import { createRouter, createWebHistory } from "vue-router";
const role = localStorage["role"];

function auth(to, from, next) {
  const token = localStorage["token"];
  if (!token) {
    next({ path: "/" });
    return;
  }
  next();
}

let routes = [
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import("../components/notFound/notFound.vue"),
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/main",
    name: "main",
    beforeEnter: auth,
    component: () => import("../views/main/main.vue"),
  },
  {
    path: "/hisobotlar",
    name: "hisobotlar",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/hisobotlar/hisobotlar.vue"),
  },
  {
    path: "/omborlar",
    name: "omborlar",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/omborlar.vue"),
  },
  {
    path: "/tayyorMahsulot/:id",
    name: "tayyorMahsulot",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/tayyor_mahsulot.vue"),
  },
  {
    path: "/tarkib",
    name: "tarkib",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/tayyorTarkib.vue"),
  },
  {
    path: "/ishlabChiqarish",
    name: "ishlabChiqarish",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/ishlabChiqarish.vue"),
  },
  {
    path: "/ombor/:id",
    name: "ombor",
    meta: { role: ["admin", "agent", "shop_user"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/ombor.vue"),
  },
  {
    path: "/omborTarix/:id",
    name: "omborTarix",
    meta: { role: ["admin", "bugalter"] },
    beforeEnter: auth,
    component: () => import("../views/omborlar/omborTarix.vue"),
  },
  {
    path: "/hodimlar",
    name: "hodimlar",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/hodimlar/hodimlar.vue"),
  },
  {
    path: "/hodimTarix/:id",
    name: "hodimTarix",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/hodimlar/hodimTarix.vue"),
  },
  {
    path: "/mijozlar",
    name: "mijozlar",
    meta: { role: ["admin", "bugalter", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/mijozlar/mijozlar.vue"),
  },
  {
    path: "/mijoz/:id",
    name: "mijoz",
    meta: { role: ["admin", "bugalter", "shop_user", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/mijozlar/mijoz.vue"),
  },
  {
    path: "/mijozTarix/:id",
    name: "mijozTarix",
    meta: { role: ["admin", "bugalter", "shop_user", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/mijozlar/mijozTarix.vue"),
  },
  {
    path: "/tg-mijozlar",
    name: "tg mijozlar",
    meta: { role: ["admin", "bugalter", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/mijozlar/telegramMijozlar.vue"),
  },
  {
    path: "/taminotchilar",
    name: "taminotchilar",
    meta: { role: ["admin", "bugalter"] },
    beforeEnter: auth,
    component: () => import("../views/taminotchilar/taminotchilar.vue"),
  },
  {
    path: "/taminotchi/:id",
    name: "taminotchi",
    meta: { role: ["admin", "bugalter"] },
    beforeEnter: auth,
    component: () => import("../views/taminotchilar/taminotchi.vue"),
  },
  {
    path: "/taminotlar",
    name: "taminotlar",
    meta: { role: ["admin", "bugalter"] },
    beforeEnter: auth,
    component: () => import("../views/taminotlar/taminotlar.vue"),
  },
  {
    path: "/taminot/:id",
    name: "taminot",
    meta: { role: ["admin", "bugalter"] },
    beforeEnter: auth,
    component: () => import("../views/taminotlar/taminot.vue"),
  },
  {
    path: "/kassalar",
    name: "kassalar",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/kassa/kassa.vue"),
  },
  {
    path: "/kassa_transfer/:id",
    name: "kassa_transfer",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/kassa/transfer.vue"),
  },
  {
    path: "/kassaTarix/:id",
    name: "kassaTarix",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/kassa/tarix.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    meta: { role: ["admin"] },
    beforeEnter: auth,
    component: () => import("../views/setting/setting.vue"),
  },
  {
    path: "/chiqimlar",
    name: "chiqimlar",
    meta: { role: ["admin", "agent"] },
    beforeEnter: auth,
    component: () => import("../views/chiqimlar/chiqimlar.vue"),
  },
];

routes = routes.filter((item) => {
  if (item.meta) return item.meta.role.includes(role);
  else return item;
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
