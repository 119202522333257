<template>
  <Firebase v-if="$route.name !== 'login'" />
  <div id="main">
    <AppInstall v-if="$route.name !== 'login'" />
    <router-view />
  </div>
  <router v-if="$route.name !== 'login'" />
  <loading v-if="isloading" />
</template>

<script>
import router from "./components/router/router.vue";
import loading from "./components/animation/loading.vue";
import AppInstall from "./components/AppInstall.vue";
import Firebase from "./components/Firebase/Firebase.vue";

export default {
  name: "app",
  components: { router, loading, AppInstall, Firebase },
  data() {
    return {};
  },
  computed: {
    isloading() {
      return this.$store.getters.loading;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="css">
@import url(./components/style/main.css);
</style>
